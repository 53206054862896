import React, { useState } from "react";
// import audio1 from "../images/khoisan_image/Audio/audio3.mp3";
import RecorderCd from "./RecorderCd";
import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import TenSecondRewind from "./TenSecondRewind";
import TenSeconForward from "./TenSeconForward";
// import { Icon, UserAvatar } from "./component/user/UserAvatar";
import { musicData } from "./Musicdata";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import musicImage from "../images/khoisan_image/king.png";
import "../App.css";
function MusicComponent() {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [currentSong, setCurrentSong] = useState(null);
  const [currentAlbumCover, setCurrentAlbumCover] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const handleSongClick = (song,index) => {
    setCurrentSong(song.Song_Data);
    setHoveredIndex(index)
    setCurrentAlbumCover(song.Album_Cover);
  };
  //newly added
  const handlePlay = () => {
    setIsPlaying(true);
  };

  const handlePause = () => {
    setIsPlaying(false);
  };
//   const [downloadSong, setDownloadSong] = useState(null);
//   const handleDownload = (song) => {
//     setDownloadSong(downloadSong.Song_Data);
//   };
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6 mb-2 d-flex justify-center">
          <div className="card bg-transparent border-0 albumcoverfull">
            <div className="card shadow albumcover p-3 border-0">
              <div className="card-img d-flex justify-center">
                <img
                  src={
                    currentAlbumCover
                      ? currentAlbumCover
                      : musicImage
                  }
                  className="albumcoverimg"
                  alt="album cover"
                />
              </div>
              {/* <div className="card-body">
                <div className="row">
                  <div className="col-md-2 d-flex align-center">
                    <img src={Recorder} alt="cd icon" className="img-fluid " />
                    <RecorderCd isPlaying={isPlaying}  />
                  </div>
                  <div className="col-md-10">
                    <AudioPlayer
                      src={currentSong || audio1}
                      customIcons={{
                        forward: <TenSeconForward />,
                        rewind: <TenSecondRewind />,
                        volume: <Icon name="vol" />,
                        volumeMute: <Icon name="vol-off" />,
                        
                      }}
                      onPlay={handlePlay}
                      onPause={handlePause}
                      customAdditionalControls={[
                        RHAP_UI.LOOP,
                        <div>
                          <a href={currentSong || audio1} download className="btn btn-icon ms-2">
                            <Icon name="download" className="fs-3" />
                          </a>
                        </div>,
                      ]}
                    />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-2">
          <div className="audiolists">
            <div className="card border-0  p-3">
              <div className="d-flex justify-content-between ">
                <div>
                  {/* <h1 className="artistname">Khoisan</h1> */}
                  <p className="playlistdetails">
                    <span>
                      Sovereign State of <br /> Good Hope
                    </span>
                    <span>
                      {" "}
                      {/* <Icon name="dot"></Icon>2024 */}
                    </span>
                    <span>
                      {" "}
                      {/* <Icon name="dot"></Icon> */}
                      {musicData.length} Podcasts
                    </span>
                  </p>
                </div>
                {/* <div>
                  <h1 className="artistname">4.5 / 5</h1>
                  <p className="d-flex justify-center text-warning">
                    <span>
                      <Icon name="star-fill" className="fs-4" />
                    </span>
                    <span>
                      <Icon name="star-fill" className="fs-4" />
                    </span>
                    <span>
                      <Icon name="star-fill" className="fs-4" />
                    </span>
                    <span>
                      <Icon name="star-fill" className="fs-4" />
                    </span>
                    <span>
                      <Icon name="star-half-fill" className="fs-4" />
                    </span>
                  </p>
                </div> */}
              </div>
              {/* Music List starts here */}
              <div
                className="mt-2 scrollablecard p-1"
                style={{ height: "480px", overflowY: "scroll", position: "relative" }}
              >
                {musicData.map((card, index) => (
                  <div
                    key={index}
                    className={`card border-0  mb-2 d-flex justify-content-between flex-row align-items-center p-3 songcard ${
                      hoveredIndex === index ? "custom-podcast-bg-color" : ""
                    }`}
                    onClick={() => handleSongClick(card,index)}
                    // onMouseEnter={() => setHoveredIndex(index)}
                    // onMouseLeave={() => setHoveredIndex(null)}
                  >
                    <div className="d-flex align-center">
                      <div className="d-flex ">
                        {/* <UserAvatar theme="primary" image={card.Album_Cover} className="sq md music-avatar" style={{backgroundColor:'transparent'}}></UserAvatar> */}
                      </div>
                      <span className={`ms-4 songname ${hoveredIndex === index ? "text-dark " : ""}`} style={{width: '25ch'}} title={card.Song_Name}>
                        {card.Song_Name}
                      </span>
                    </div>
                    <div>
                      {/* <UncontrolledDropdown>
                        <DropdownToggle
                          className={`dropdown-toggle p-0 bg-transparent border-0 ${
                            hoveredIndex === index ? "text-dark " : "text-dark"
                          }`}
                        >
                          <Icon name="more-h"></Icon>
                        </DropdownToggle>
                        <DropdownMenu className="bg-dark">
                          <ul className="link-list-plain p-0 m-0">
                            <li>
                              <DropdownItem
                                tag="a"
                                download
                                className="text-white fw-bolder downloaddropdown fs-16px justify-center p-1 "
                                href={card.Song_Data}
                                // onClick={(ev) => ev.preventDefault()}
                              >
                                <Icon name="download" className="fs-5 text-secondary-dim"></Icon>
                                <span>Download</span>
                              </DropdownItem>
                            </li>
                          </ul>
                        </DropdownMenu>
                      </UncontrolledDropdown> */}
                        <a
                          download
                          className="text-white fw-bolder downloaddropdown fs-16px justify-center p-1 bg-transparent border-0"
                          href={card.Song_Data}
                        >
                          {/* <Icon name="download" className="fs-5 text-secondary-dim text-black"></Icon> */}
                        </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MusicComponent;
