import React, { useState, useEffect, useCallback } from "react";
import Isotope from 'isotope-layout';
import AOS from 'aos';
import 'aos/dist/aos.css';
import "../App.css";
import king from "../images/khoisan_image/king.png";
import image from "../images/khoisan_image/image1.jpeg";
import image1 from "../images/khoisan_image/image2.jpeg";
import image2 from "../images/khoisan_image/image3.jpeg";
import image3 from "../images/khoisan_image/image5.jpeg";
import image4 from "../images/khoisan_image/image6.jpeg";
import image5 from "../images/khoisan_image/image8.jpeg";
import image6 from "../images/khoisan_image/image4.jpeg";
import image7 from "../images/khoisan_image/image-11.jpeg";
import image8 from "../images/khoisan_image/image-12.jpeg";
import image13 from "../images/khoisan_image/image-13.jpg";
import image14 from "../images/khoisan_image/image-14.jpg";
import image15 from "../images/khoisan_image/image-15.jpg";
import image16 from "../images/khoisan_image/image-16.jpg";
import image17 from "../images/khoisan_image/image-17.jpg";
import image18 from "../images/khoisan_image/image-18.jpg";
import image19 from "../images/khoisan_image/image-19.jpg";
import image22 from "../images/khoisan_image/image-22.jpg";
import image23 from "../images/khoisan_image/image-23.jpg";
import image25 from "../images/khoisan_image/image25.jpg";
import image26 from "../images/khoisan_image/image-26.jpg";
import image27 from "../images/khoisan_image/image-27.jpg";
import image29 from "../images/khoisan_image/image-29.jpg";
import image30 from "../images/khoisan_image/image-30.jpg";
import image31 from "../images/khoisan_image/image-31.jpg";
import image32 from "../images/khoisan_image/image-32.jpg";
import image33 from "../images/khoisan_image/image-33.jpg";

import video1 from "../images/khoisan_image/videos/video1.mp4";
import video2 from "../images/khoisan_image/videos/video2.mp4";
import video3 from "../images/khoisan_image/videos/video3.mp4";
import video4 from "../images/khoisan_image/videos/video4.mp4";
import video5 from "../images/khoisan_image/videos/video5.mp4";
import video6 from "../images/khoisan_image/videos/video6.mp4";
import video7 from "../images/khoisan_image/videos/video7.mp4";
import video8 from "../images/khoisan_image/videos/video8.mp4";
import video9 from "../images/khoisan_image/videos/video9.mp4";

// import audio1 from "../images/khoisan_image/Audio/audio1.mp3";
// import audio2 from "../images/khoisan_image/Audio/audio2.mp3";
// import audio3 from "../images/khoisan_image/Audio/audio3.mp3";
// import audio4 from "../images/khoisan_image/Audio/audio4.mp3";

import { Modal, ModalBody, Nav, NavItem, TabContent, TabPane } from "reactstrap";
import { NavLink } from "react-router-dom";
import MusicComponent from "./MusicComponent.jsx";
import { Icon } from "./component/icon/Icon";
import "../images/khoisan_css/music-player.css";
import ImageViewer from "react-simple-image-viewer";
import ModelViewer from "./ModelViewer.jsx";
import { CSSTransition, TransitionGroup } from "react-transition-group";


function Portfolio() {
  const [activeTab, setActiveTab] = useState("1");
  const [youtubeLinks, setYoutubeLinks] = useState([]);
  const [modal, setModal] = useState(false);
  const [videoSrc, setVideoSrc] = useState("");
  const [AudioPlaying, setAudioPlaying] = useState(false);
  const [audioPlaying, setaudioPlaying] = useState("");
  const [audioSrc, setAudioSrc] = useState("");

  const [selectedLocalVideo, setSelectedLocalVideo] = useState(null);

  const openModal = (src, type) => {
    if (type === "youtube") {
      const videoId = getYoutubeVideoId(src);
      const embedSrc = `https://www.youtube.com/embed/${videoId}`;
      setVideoSrc(embedSrc);
    } else if (type === "local") {
      setVideoSrc(src);
    }
    setModal(true);
  };
  const closeLocalVideoModal = () => {
    setSelectedLocalVideo(null);
    setModal(false);
  };

  const closeModal = () => {
    setVideoSrc("");
    setModal(false);
  };

  useEffect(() => {
    const fetchedYoutubeLinks = [
      "https://www.youtube.com/watch?v=gGdQYqgvKQw",
      "https://www.youtube.com/watch?v=oLuP014_Ufg",
      "https://www.youtube.com/watch?v=XvoLvBI6DQ4",
    ];
    setYoutubeLinks(fetchedYoutubeLinks);
  }, []);

  const handleTabClick = (index) => {
    setActiveTab(index);
    AOS.refresh(); // Reinitialize AOS when tab changes
  };

  const UserData = [
    {
      image: image,
      description: "gallery-image-1",
    },
    {
      image: image1,
      description: "gallery-image-2",
    },
    {
      image: image2,
      description: "gallery-image-3",
    },
    {
      image: image4,
      description: "gallery-image-4",
    },
    {
      image: image5,
      description: "gallery-image-5",
    },
    {
      image: image6,
      description: "gallery-image-6",
    },
    {
      image: image3,
      description: "gallery-image-7",
    },
    {
      image: image7,
      description: "gallery-image-8",
    },
    {
      image: image8,
      description: "kids greeting an old chief",
    },
    {
      image: image13,
      description: "gallery-image-10",
    },
    {
      image: image14,
      description: "chief petrus vaalbooi of the kalahari san at a function in the cape town gardens when we renamed",
    },
    {
      image: image15,
      description: "gallery-image-12",
    },
    {
      image: image16,
      description: "The king awarded professor hakan with an award gratitude and also as an advisor",
    },
    {
      image: image17,
      description: "One of the hundreads of marchesto parliament by our khoisan/colored people",
    },
    {
      image: image18,
      description: "Well known khoisan poet.His name was Don Mattera",
    },
    {
      image: image19,
      description: "khoisan Tribes",
    },
    {
      image: image22,
      description: "rock art",
    },
    {
      image: image23,
      description: "gallery-image-18",
    },
    {
      image: image25,
      description: "khoisan people doing a dance",
    },
    {
      image: image26,
      description: "Kalahari Bushman in his traditional clothing he wears every day speaking to soldier at border post",
    },
    {
      image: image27,
      description: "gallery-image-21",
    },
    {
      image: image29,
      description: "gallery-image-22",
    },
    {
      image: image30,
      description: "khoisan people singing a song",
    },
    {
      image: image31,
      description: "khoisan children",
    },
    {
      image: image32,
      description: "gallery-image-25",
    },
    {
      image: image33,
      description: "gallery-image-26",
    },
  ];

  // const audioData = [
  //   {
  //     type: "audio",
  //     content: audio1,
  //     image: king,
  //     text: "History of Sovereign State of Good Hope",
  //   },
  //   {
  //     type: "audio",
  //     content: audio2,
  //     image: image1,
  //     text: "Ancient Culture",
  //   },
  //   {
  //     type: "audio",
  //     content: audio3,
  //     image: image2,
  //     text: "History of Khoisan Tribes",
  //   },
  //   {
  //     type: "audio",
  //     content: audio4,
  //     image: image3,
  //     text: "Tradition",
  //   },
  // ];

  const VideoData = [
    { type: "youtube", content: "https://www.youtube.com/watch?v=gGdQYqgvKQw" },
    { type: "youtube", content: "https://www.youtube.com/watch?v=oLuP014_Ufg" },
    { type: "youtube", content: "https://www.youtube.com/watch?v=XvoLvBI6DQ4" },
    { type: "local", content: video1 },
    { type: "local", content: video2 },
    { type: "local", content: video3 },
    { type: "local", content: video4 },
    { type: "local", content: video5 },
    { type: "local", content: video6 },
    { type: "local", content: video7 },
    { type: "local", content: video8 },
    { type: "local", content: video9 },
  ];

  const handleAudioPlay = () => {
    setAudioPlaying(!audioPlaying);
  };

  const handleAudioSrcClick = (src) => {
    if (src === audioSrc && audioPlaying) {
      setAudioPlaying(false);
    } else {
      setAudioPlaying(true);
      setAudioSrc(src);
    }
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  // image viewer
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  useEffect(() => {
    const isotope = new Isotope('.isotope-container', {
      itemSelector: '.isotope-item',
      layoutMode: 'fitRows',
    });

    return () => {
      isotope.destroy();
    };
  }, [activeTab]);


  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true, // Optional: whether animation should happen only once - while scrolling down
    });
  }, []);

  return (
    <div id="gallery">
      <div className="portfolio" id="portfolio">
        <div className="card-inner gallery-section container mt-5">
          <div className="head text-center mb-3">
            <h2>
              <span className="text-theme crimson-text home-text heading-spacing " style={{ textTransform: "uppercase", color: "#FFA500" }}>Gallery</span>
            </h2>
          </div>
          <Nav tabs className="mt-n3 border-0 mb-1 ms-3">
            <NavItem>
              <NavLink
                tag="a"
                href="#tab"
                className={`crimson-text me-3`}
                style={{ cursor: "pointer", borderBottom: activeTab === "1" ? "3px solid #8C0821" : "none" }}
                onClick={(ev) => {
                  ev.preventDefault();
                  toggle("1");
                }}
              >
                Photos
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag="a"
                href="#tab"
                className={`crimson-text me-3`}
                style={{ cursor: "pointer", borderBottom: activeTab === "2" ? "3px solid #8C0821" : "none" }}
                onClick={(ev) => {
                  ev.preventDefault();
                  toggle("2");
                }}
              >
                Videos
              </NavLink>
            </NavItem>
            {/* <NavItem>
            <NavLink
              tag="a"
              href="#tab"
              className={`crimson-text`}
              style={{ cursor: "pointer", borderBottom: activeTab === "3" ? "3px solid #8C0821" : "none" }}
              onClick={(ev) => {
                ev.preventDefault();
                toggle("3");
              }}
            >
              Podcast
            </NavLink>
          </NavItem> */}
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <TransitionGroup className="isotope-container">
                <div className="row g-gs">
                  {UserData.map((user, index) => (
                    <CSSTransition key={index} timeout={500} classNames="fade">
                      <div
                        className="col-sm-6 col-lg-4 col-xxl-3 isotope-item second hover01"
                        data-aos="fade-up" // Apply AOS effect
                      >
                        <div className="gallery mt-4 img-div">
                          <img
                            src={user.image}
                            className="rounded-3"
                            onClick={() => openImageViewer(index)}
                            width="300"
                            style={{ margin: "2px" }}
                            alt={user.description}
                          />
                        </div>
                      </div>
                    </CSSTransition>
                  ))}
                </div>
              </TransitionGroup>

              {isViewerOpen && (
                <ImageViewer
                  src={UserData.map((user) => user.image)}
                  currentIndex={currentImage}
                  disableScroll={false}
                  closeOnClickOutside={true}
                  onClose={closeImageViewer}
                />
              )}
            </TabPane>
            <TabPane tabId="2">
              <TransitionGroup className="isotope-container">
                <div className="row g-gs"  >
                  {VideoData.map((video, index) => (
                    <CSSTransition key={index} timeout={500} classNames="fade">
                      <div
                        className="col-sm-6 col-lg-4 col-xxl-3 isotope-item "
                      // Apply AOS effect
                      >
                        <div className="card-bordered gallery card mt-4">
                          {video.type === "youtube" ? (
                            <div className="gallery-image popup-image" onClick={() => openModal(video.content, "youtube")}>
                              <img
                                className="w-100 rounded-top"
                                src={`https://img.youtube.com/vi/${getYoutubeVideoId(video.content)}/0.jpg`}
                                alt="YouTube video thumbnail"
                                style={{ objectFit: "none", cursor: "pointer" }}
                              />
                              {/* <Icon className="ni ni-play-circle-fill video-icon" style={{ cursor: "pointer" }}></Icon> */}

                            </div>
                          ) : (
                            <div className="gallery-image popup-image" onClick={() => openModal(video.content, "local")}>
                              <video className="w-100 rounded-top video-tab" src={video.content} />
                              {/* <Icon className="ni ni-play-circle-fill video-icon" style={{ cursor: "pointer" }}></Icon> */}
                            </div>
                          )}
                        </div>
                      </div>
                    </CSSTransition>
                  ))}
                </div>
              </TransitionGroup>
            </TabPane>
            <TabPane tabId="3">
              <MusicComponent />
            </TabPane>
          </TabContent>
        </div>
        <Modal isOpen={modal} toggle={closeModal} size="lg" className="custom-modal">
          <ModalBody className="w-100 video-modal">
            <button className="close-button" onClick={closeModal}>
              X
            </button>

            {videoSrc &&
              (videoSrc.includes("youtube.com") ? (
                <iframe
                  title="Video"
                  className="embed-responsive-item video-iframe"
                  src={videoSrc}
                  allowFullScreen
                ></iframe>
              ) : (
                <video controls className="embed-responsive-item video-iframe">
                  <source src={videoSrc} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ))}
          </ModalBody>
        </Modal>
      </div>
    </div>
  );
}

function getYoutubeVideoId(url) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  const match = url.match(regExp);
  return match && match[2].length === 11 ? match[2] : null;
}

export default Portfolio;
