import logo from './logo.svg';
import './App.css';
import Header from './Components/Header';
import About from './Components/About';
import Portfolio from './Components/Portfolio';
import Journal from './Components/Journal';
import Contact_us from './Components/Contact_us';
import { Route, Routes } from 'react-router-dom';
import Timeline2 from './Components/Timeline2';
import Footer from './Components/Footer';
function App() {
  return (
    <div className="App">

      <Header />
      <About />
      <Timeline2/>
      <Portfolio />
      <Journal /> 
      <Contact_us /> 
      <Footer/>
      

      {/* <Routes>
          <Route path="/" element={<Header />} />
          <Route path="/about" element={<About />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/journal" element={<Journal />} />
          <Route path="/contactus" element={<Contact_us />} />


        </Routes> */}

      

    </div>
  );
}

export default App;
