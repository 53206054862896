import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Typed from 'typed.js';
import { About_img, Carousel_1, carousel_3, carousel_4, king_logo1, welcome } from '../images';
import './Header.css';  // Assuming you are importing CSS from an external file

export default function Header() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleScroll = () => {
    const header = document.getElementById('header');
    if (window.scrollY > 50) {
      header.classList.add('header-scrolled');
    } else {
      header.classList.remove('header-scrolled');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // useEffect(() => {
  //   const typed = new Typed('.typed', {
  //     strings: ['KING KHOEBAHA CALVIN CORNELIUS III'],
  //     typeSpeed: 100,
  //     backSpeed: 50,
  //     backDelay: 2000,
  //     loop: true,
  //   });

  //   return () => {
  //     typed.destroy();
  //   };
  // }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <header id="header" className="fixed-top header-inner-pages">
        <div className="container d-flex align-items-center justify-content-between">
          <a href="index.html" className="logo">
            <img src={king_logo1} alt="" className="img-fluid " width={250} />
          </a>
          <nav id="navbar" className="navbar">
            <ul>
              <li>
                <a className="nav-link scrollto" href="#hero">
                  Home
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#aboutus">
                  About
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#gallery">
                  Gallery
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#vision">
                  Vision
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#contact">
                  Contact
                </a>
              </li>
            </ul>
            <i className="bi bi-list mobile-nav-toggle me-3" onClick={openModal} />
          </nav>
        </div>
      </header>

      <div id="hero" className="home">
        {/* <div className="container">
          <div className="hero-content">
            <h1>
              I'm <span className="typed" />
            </h1>
            <p></p>

            <ul className="list-unstyled list-social">
              <li><a href="#"><i className="bi bi-facebook"></i></a></li>
              <li><a href="#"><i className="bi bi-twitter"></i></a></li>
              <li><a href="#"><i className="bi bi-instagram"></i></a></li>
              <li><a href="#"><i className="bi bi-linkedin"></i></a></li>
            </ul>
          </div>
        </div> */}
      </div>

      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-contents" onClick={(e) => e.stopPropagation()}>
            <i className="bi bi-x close-modal text-dark" onClick={closeModal} />
            <ul className="modal-nav">
              <li>
                <a className="nav-link scrollto" href="#hero" onClick={closeModal}>
                  Home
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#about" onClick={closeModal}>
                  About
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#portfolio" onClick={closeModal}>
                  Gallery
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#journal" onClick={closeModal}>
                  Vision
                </a>
              </li>
              <li>
                <a className="nav-link scrollto" href="#contact" onClick={closeModal}>
                  Contact
                </a>
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
}
