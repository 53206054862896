import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import About_img from '../images/about_img.png';

function About() {

   return (
    <div id='aboutus'>
      <section id="about" className={`container scroll-margin`} data-aos="fade-up">
        <div className="container">
          <div className="section-title text-center">
            <h1 className='' style={{ textDecoration: 'underline',textDecorationThickness:"1.5px", fontFamily: 'Times New Roman',letterSpacing:"6px",color:"#FFA500", textUnderlineOffset: "7px",fontWeight: '-moz-initial'}}>KING</h1>
          </div>
        </div>

        <div className="container about-container">
          <div className="section-title">
            <h3 className='' style={{ textAlign: 'center', fontFamily: 'Times New Roman', fontSize: '30px', fontWeight: 'bold',color:"#FFA500" }}>KHOEBAHA CALVIN CORNELIUS III</h3>
          </div>

          <div className="row">
            <div className="col-lg-4">
              <img
                src={About_img}
                className="img-fluid"
                alt="Khoebaha Calvin Cornelius III"
                style={{ height: 400 }}
              />
            </div>
            <div className="col-lg-8 pt-0 pt-lg-0 content-about" style={{ marginTop: "-20px" }}> 
              <p style={{ lineHeight: "3.4", color: "black",fontFamily:"Crimson Text, serif", fontSize: '18px',fontWeight:"inherit" }}>
                The hereditary tribal leader of the Khoisan Nation is known as Gaob, which
                equates to <b>“King”</b> in the English Language. <b>Khoebaha</b> (His Praise Name
                means Man Coming) <b>Calvin Cornelius III</b> comes from an ancient
                bloodline of Khoisan tribal leaders, and he has inherited the position of King
                through direct bloodlines from antiquity. Gaob Cornelius III, the recognized
               <b> <i> The head of the Royal House of the Khoisan Nation</i> </b>, has engaged in this process
                of secession on behalf of the Khoisan Nation, and those minorities who chose
                to join the Sovereign State of Good Hope.
                <br />
                {/* “Khoebaha Calvin Cornelius III comes from a long lineage of tribal leaders, and has inherited the position, and therefore he is the only person who has right to engage in this process of secession on behalf of the Khoisan, as the recognised head of the Royal House of the Khoisan Nation.” */}
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default About;
