import React from 'react';
import '../App.css'; // Make sure to import the CSS file

function Footer() {
  return (
    <div id="footer" className="text-center">
      <div className="container-fluid">
        <div className="socials-media text-center mb-5">
          <ul className="list-unstyled">
            <li>
              <a href="https://www.facebook.com/kingkhoebaha.cornelius/" className="facebook" aria-label="Facebook" target="_blank">
                <span className='text-box'>FACEBOOK</span>
                <i className="bi bi-facebook icon text-white" />
              </a>
            </li>
            <li>
              <a href="#" className="twitter" aria-label="Twitter">
                <span className='text-box'>TWITTER</span>
                <i className="bi bi-twitter icon text-white" />
              </a>
            </li>
            <li>
              <a href="#" className="instagram" aria-label="Instagram">
                <span className='text-box '>INSTAGRAM</span>
                <i className="bi bi-instagram icon text-white" />
              </a>
            </li>
            <li>
              <a href="#" className="linkedin" aria-label="LinkedIn">
                <span className='text-box'>LINKEDIN</span>
                <i className="bi bi-linkedin icon text-white" />
              </a>
            </li>
          </ul>
        </div>
        <span style={{ color: "grey", fontSize: "13px" }} >
          © 2024 &nbsp; KHOEBAHA CALVIN CORNELIUS III .&nbsp; ALL RIGHTS RESERVED.
        </span>
      </div>
    </div>
  );
}

export default Footer;
