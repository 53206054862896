import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { motto_1, motto_2, motto_3 } from '../images';

function Journal() {
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    AOS.init({ duration: 1000 });

    const timeoutId = setTimeout(() => {
      setIsAnimating(!isAnimating);
    }, 1000); // Same duration as the AOS animation

    // Cleanup timeout on unmount
    return () => {
      clearTimeout(timeoutId);
    };
  }, [isAnimating]);

  return (
    <div id='vision'>
      <div id="journal" className={`text-left paddsection bg-white ${isAnimating ? 'margin-scroll' : ''}`} data-aos="fade-up">
        <div className="container">
          <div className="section-title text-center">
            <h2 className='fw-400 mb-5' style={{color:"#f5a010"}}> Vision </h2> 
          </div>
        </div>

        <div className="container w-auto">
          <div className="journal-block">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="journal-info">

                  <a  href="https://www.youtube.com/watch?v=XvoLvBI6DQ4&t=28s"  target="_blank" >
                  <img
                    src={motto_1}
                    className="img-responsive"
                    alt="img"
                    width={415}
                    height={311}
                    href="https://www.youtube.com/watch?v=XvoLvBI6DQ4&t=28s" 
                   
                  />
                  </a>

                  <div className="journal-txt ms-3">
                    <h3>
                      <a href="https://www.youtube.com/watch?v=XvoLvBI6DQ4&t=28s" target="_blank" rel="noopener noreferrer">
                        SECEDE FROM SOUTH AFRICA
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="journal-info">

                  <a  href="https://sovereignstateofgoodhope.com/" 
                    target="_blank"> 
                  <img
                    src={motto_2}
                    className="img-responsive"
                    alt="img"
                  />
                  </a>

                  <div className="journal-txt">
                    <h3 className='d-flex justify-content-center'>
                      <a href="https://sovereignstateofgoodhope.com/" target="_blank" rel="noopener noreferrer"> OWN COUNTRY </a>
                    </h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="journal-info">
                <a href="https://sovereignstateofgoodhope.com/learn" 
                    target="_blank">
                  <img
                    src={motto_3}
                    className="img-responsive"
                    alt="img"
                    href="https://sovereignstateofgoodhope.com/learn" 
                    target="_blank"
                  />
                   </a>
                   
                  <div className="journal-txt">
                    <h3 className='d-flex justify-content-center'>
                      <a href="https://sovereignstateofgoodhope.com/learn" target="_blank" rel="noopener noreferrer">
                        FLAG OF OUR COUNTRY
                      </a>
                    </h3>
                  </div>
                </div>
              </div>  
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Journal;
