// import audio1 from "../images/khoisan_image/Audio/audio1.mp3";
// import audio2 from "../images/khoisan_image/Audio/audio2.mp3";
// import audio3 from "../images/khoisan_image/Audio/audio3.mp3";
// import audio4 from "../images/khoisan_image/Audio/audio4.mp3";
import musicImage from "../images/khoisan_image/king1.jpg";
import musicImage1 from "../images/khoisan_image/flag1.jpg";
import musicImage2 from "../images/khoisan_image/king_home.jpg";
import musicImage3 from "../images/khoisan_image/culture.jpg";

export const musicData = [
    {
        "id" :1,
        "Song_Name": "SSOGH King Speaks about Past",
        "Artist Name": "The random artist1",
        "Album_Cover": musicImage,
        // "Song_Data":audio1
    },
    {
        "id" :2,
        "Song_Name": "SSOGH issues eviction notices and raises the flag | 16 July 2018",
        "Artist Name": "The random artist2",
        "Album_Cover": musicImage1,
        // "Song_Data":audio2
    },
    {
        "id" :3,
        "Song_Name": "SSOGH's King address at 2020",
        "Artist Name": "The random artist3",
        "Album_Cover": musicImage2,
        // "Song_Data":audio3
    },
    {
        "id" :4,
        "Song_Name": "SSOGH Culture: Honoring the Past",
        "Artist Name": "The random artist4",
        "Album_Cover": musicImage3,
        // "Song_Data":audio4
    },
];