import React from 'react';
import './Timeline.css';
import { history_1, history_2, history_3, history_4 } from '../images';

const Timeline2 = () => {
    return (
        <section className="timeline container">
            <div className="row align-items-center">
                <div className="col-lg-6">
                    <div className="event left" data-aos="fade-up">
                        <div className="content">
                            <h2>1999</h2>
                            <h3>Establishing Foundations</h3>
                            <p>The government invited us to participate in drafting a white paper, making us realize the need for proper structures. We approached PricewaterhouseCoopers to create a trust document for a national trust to manage funds properly, advising that funds be placed in a trust account to maintain independence.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 text-center mt-4 mt-lg-0" data-aos="fade-up">
                    <img src={history_1} className="timeline-icon" alt="history_1" />
                </div>
            </div>

            <div className="row align-items-center">
                <div className="col-lg-6 order-lg-2">
                    <div className="event right" data-aos="fade-up">
                        <div className="content">
                            <h2 className=''>2001</h2>
                            <h3>Advocacy and Representation</h3>
                            <p>During the launch of Contralesa in the Western Cape, I was appointed vice president as the sole Khoisan representative. Despite feeling out of place at national meetings due to language barriers, I represented Contralesa at the United Nations, emphasizing that the Khoisan are the indigenous people of South Africa, distinct from others in Africa.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 text-center mt-4 mt-lg-0" data-aos="fade-up">
                    <img src={history_2} className="timeline-icon" alt="history_2" />
                </div>
            </div>

            <div className="row align-items-center">
                <div className="col-lg-6">
                    <div className="event left" data-aos="fade-up">
                        <div className="content">
                            <h2>2002</h2>
                            <h3>Founding the Khoisan Cultural Movement Trust</h3>
                            <p>To address the misuse of the trust, we obtained a copy of the trust document from PricewaterhouseCoopers and registered the Khoisan Cultural Movement Trust. This established a structure with trustees and a royal council for guidance, emphasizing the need for precision in securing our liberty.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 text-center mt-4 mt-lg-0" data-aos="fade-up">
                    <img src={history_3} className="timeline-icon" alt="history_3" />
                </div>
            </div>

            <div className="row align-items-center">
                <div className="col-lg-6 order-lg-2">
                    <div className="event right" data-aos="fade-up">
                        <div className="content">
                            <h3 className='mb-2.5'>Leadership and Cultural Preservation</h3>
                            <p>Our structure includes an inner royal council and an outer advisory council, each member contributing unique skills for the community's benefit. I focus on leadership and heritage, not politics, advocating for truth, kindness, love, and peace. Despite our 3.6 million population, the Khoisan remain unrecognized legislatively. I call upon those with gifts and abilities to help preserve our people and culture.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 text-center mt-4 mt-lg-0" data-aos="fade-up">
                    <img src={history_4} className="timeline-icon" alt="history_4" style={{width:"170px"}}/>
                </div>
            </div>
        </section>
    );
}

export default Timeline2;
