export const About_img = require("./about_img.png");



// Motto section 

export const motto_1 = require("./country_img.jpg");
export const motto_2 = require("./motto2.jpg")
export const motto_3 = require("./motto3.jpg");


export const king_logo = require("./khoeba_logo.png");
export const king_logo1 = require("./khoebaha_logo_white.png");



export const history_1 = require("./government.png");
export const history_2 = require("./conference.png");
export const history_3 = require("./amulet.png");
export const history_4 = require("./leadership.png");

