import React, { useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

export default function ContactUs() {

  function onChange(value) {
    console.log("Captcha value:", value);
  }
  return (
    <div id="contact" className="paddsection">
      <div className="container" data-aos="fade-up">
        <div className="contact-block1">
          <div className="row">
            <div className="col-lg-6">
              <div className="contact-contact">
                <h2 className="mb-30">GET IN TOUCH</h2>
                <ul className="contact-details text-dark">
                  <li>
                    <span>23 Main, Street</span>
                  </li>
                  <li>
                    <span>New York, United States</span>
                  </li>
                  <li>
                    <span>+88 01912704287</span>
                  </li>
                  <li>
                    <span>info@siteanme.com</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              <form
                action="forms/contact.php"
                method="post"
                role="form"
                className="php-email-form"
              >
                <div className="row gy-3">
                  <div className="col-lg-6">
                    <div className="form-group contact-block1">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        id="name"
                        placeholder="Your Name"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder="Your Email"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="subject"
                        id="subject"
                        placeholder="Subject"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <textarea
                        className="form-control"
                        name="message"
                        placeholder="Message"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <ReCAPTCHA
                      sitekey="6LcKrgwqAAAAAKy26sRx1EZl6i_PWzJnT62DJ2eQ"
                      onChange={onChange}
                    />,
                  </div>
                  <div className="col-lg-12">
                    <div className="loading">Loading</div>
                    <div className="error-message"></div>
                    <div className="sent-message">
                      Your message has been sent. Thank you!
                    </div>
                  </div>
                  <div className="mt-0">
                    <input
                      type="submit"
                      className="btn btn-default btn-send"
                      value="Send message"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
